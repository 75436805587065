<template lang="pug">
    //.flex.items-center(class="text-xs 768:text-sm")
    //    span.block.mr-10 {{ APPLIES.name }}
    //    span.font-arialBold.text-accent.block 2057
</template>

<script>
import { APPLIES } from '@/configs/applies';
//Store
import { UserPostsNameSpace, UserPostsActionTypes, UserPostsGetterTypes } from '@/store/user-posts/types';

export default {
    name: "ApplyTopNavigation",
    computed: {
        APPLIES() {
            return APPLIES
        },
        totalPosts() {
            return this.$store.getters[`${UserPostsNameSpace}/${UserPostsGetterTypes.GetTotal}`].total
        },
    },
    mounted() {
        // this.getPostCount()
    },
    methods: {
        async getPostCount() {
            try {
                await this.$store.dispatch(`${UserPostsNameSpace}/${UserPostsActionTypes.GetTotal}`)
            } catch (error) {
                console.error(error)
            }
        }
    }
}
</script>
