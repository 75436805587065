<template lang="pug">
    div.bg-main
        mos-head
        header(
            v-if="!isMain"
            class="flex justify-center h-100"
            :class="{'h-40 768:h-80 1024:h-100 1280:h-150 w-full 1024:absolute z-200': isMainPage && !isMobile}"
        )
            section(
                class="w-full 1280:w-1160 flex items-center 1024:justify-between 768:justify-center justify-between"
                :class="{'1024:w-750 1280:w-1000 1366:w-1100 1440:w-1160': isMainPage && !isMobile, 'pr-20 pl-20': !isMainPage || isMobile}"
            )
                section
                    slot(name="header-left")
                section
                    slot(name="header-center")
                section
                    slot(name="header-right")
        section.w-full
            slot(name="main-content")
        footer.flex.w-full.bg-main.hidden(class="1024:justify-center" v-if="!isMain")
            .flex.flex-col(class="pl-20 pr-20 w-full 1024:pl-0 1024:pr-0 1024:flex-row 1280:w-1200 p-10 pb-30 1024:items-center justify-between")
                slot(name="footer-left")
                slot(name="footer-center")
                slot(name="footer-right")
</template>

<script>
import { isMobile } from '@/helpers/is-mobile';
import MosHead from '@/components/common/head/head.vue';

export default {
    name: "MainLayout",
    components: {
      'mos-head': MosHead,
    },
    computed: {
        isMainPage() {
            if (this.$route.name === 'Home') return true
            else return false
        },
        isMapPage() {
            if (this.$route.name === 'Map') return true
            else return false
        },
        isMobile() {
            return isMobile()
        },
        isMain() {
          return this.$route.name === 'Home';
        }
    }
}
</script>
