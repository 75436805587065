<template lang="pug">
    main-layout
      // - header
      template(v-slot:header-right)
        .flex.alignt-center(class="hidden 1024:flex")
            //- top-navigation(class="mr-50")
            applies
        mobile-menu(class="768:hidden" v-if="['Apply', 'Details'].includes(currentRoute) === false")
      // - main content
      template(v-slot:main-content)
          slot
      // - footer


</template>

<script>
//Components
import MainLayout from './main-layout.vue';
import Logo from '@/components/common/logo/Logo';
import MTSLogo from '@/components/specific/logo/mts';
import RUTVLogo from '@/components/specific/logo/rutv';
import FooterLogos from '@/components/specific/logo/footer-logos';
import TopNavigation from '@/components/common/top-navigation';
import Applies from '@/components/specific/applies';
import MobileMenu from '@/components/specific/mobile-menu';
import { COPY } from '@/configs/text';
import { isMobile } from '@/helpers/is-mobile'

export default {
    name: "AuthorizedLayout",
    components: {
        'main-layout': MainLayout,
        'logo': Logo,
        'mts-logo': MTSLogo,
        'rutv-logo': RUTVLogo,
        'footer-logos': FooterLogos,
        'top-navigation': TopNavigation,
        'applies': Applies,
        'mobile-menu': MobileMenu,
    },
    computed: {
      currentRoute() {
          return this.$route.name;
      },
      COPY() {
        return COPY
      },
      IMGS() {
        return {
          vk: require('@/static/social/vk.svg'),
          cross: require('@/static/cross.svg'),
        }
      },
      isMobile() {
        return isMobile
      }
    }
}
</script>

<style lang="scss" scoped>
.slide-in-top {
	-webkit-animation: slide-in-top 1s cubic-bezier(0.250, 0.460, 0.450, 0.940) backwards;
	animation: slide-in-top 1s cubic-bezier(0.250, 0.460, 0.450, 0.940) backwards;
}
@-webkit-keyframes slide-in-top {
  0% {
    -webkit-transform: translateY(-25px);
            transform: translateY(-25px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
  }
}
@keyframes slide-in-top {
  0% {
    -webkit-transform: translateY(-25px);
            transform: translateY(-25px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
  }
}
</style>