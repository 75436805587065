<template lang="pug">
    .flex.responsive
        slot
</template>

<script>
export default {
    name: "Logo",
}
</script>

<style lang="sass" scoped>
// .mts-logo
//     @media screen and (max-width: 768px)
//         width: 50px
</style>