<template lang="pug">
    .flex
        template(v-if="isOpen")
            img.z-200(:src="IMGS.close" @click="onMenu")
        template(v-else)
            img.cursor-pointer(:src="IMGS.menu" @click="onMenu")
        transition(name="fade")
            template(v-if="isOpen")
                .absolute.w-screen.h-screen.bg-main.z-100.top-0.left-0.flex.justify-center.items-center
                    .absolute.bottom-0.left-0
                        img(:src="IMGS.ellips")
                    .absolute.right-30.top-150
                        img(:src="IMGS.stars")
                    //- timer(class="w-full h-90")

                    .flex.flex-col.items-center.z-200
                        template(v-for="item in NAVIGATION")
                            template(v-if="item.name !== 'apply'")
                                a.mb-25(:href="`/#${item.name}`" @click="onMenu")
                                    img(:src="item.img")
                            template(v-else)
                                router-link(:to="`${item.name}`")
                                    img(:src="item.img")

</template>

<script>
import Timer from '@/components/specific/timer'

export default {
    name: "MobileMenue",
    components: {
        'timer': Timer,
    },
    data() {
        return {
            isOpen: false,
        }
    },
    computed: {
        IMGS() {
            return {
                menu: require('./assets/menu.svg'),
                close: require('./assets/close.svg'),
                ellips: require('./assets/ellips.svg'),
                stars: require('./assets/stars.svg'),
            }
        },
        NAVIGATION() {
            return [
                { 
                    img: require('./assets/item-sverhnovaya.svg'), 
                    name: 'sverhnovaya'
                },
                { 
                    img: require('./assets/item-map.svg'), 
                    name: 'map'
                },
                { 
                    img: require('./assets/item-prizes.svg'), 
                    name: 'prizes'
                },
                // { 
                //     img: require('./assets/item-vedushie.svg'), 
                //     name: 'vedushie'
                // },
                { 
                    img: require('./assets/item-experts.svg'), 
                    name: 'experts'
                },
                { 
                    img: require('./assets/item-services.svg'), 
                    name: 'services'
                },
                // { 
                //     img: require('./assets/item-zayavka.svg'), 
                //     name: 'apply'
                // },
            ]
        }
    },
    methods: {
        onMenu() {
            this.isOpen = !this.isOpen
        }
    }
}
</script>

<style scoped>
.fade-enter-active, .fade-leave-active {
    transition: opacity .25s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}
</style>