<template lang="pug">
    .absolute.flex.justify-between.text-white.bg-black(
        class="768:w-340 1024:w-380 h-70 1024:h-80 top-0 left-0 768:bottom-0 right-0 768:left-auto 768:top-auto rounded-b-xl 768:rounded-l-xl p-3 pt-2 pb-2")
        div.mts-timer-text.w-115.text-sm.text-gray-400.pl-3.leading-2
            span До окончания приема заявок осталось:
        .mts-timer-nums.flex.justify-between.gap-4
            div.text-center
                span.inline-block.font-arial.text-sm.text-gray-400.mb-1 дней
                .block.font-cormorantBold.text-5xl.leading-3
                    span {{ daysLeft }}
            div.flex.items-center
                span.inline-block.font-cormorant.text-3xl.leading-3.text-center.pt-2 /
            div.text-center
                span.inline-block.font-arial.text-sm.text-gray-400.mb-1 часов
                .block.font-cormorantBold.text-5xl.leading-3
                    span {{ hoursLeft < 10 ? `0${hoursLeft}` : hoursLeft }}
            div.flex.items-center
                span.inline-block.font-cormorant.text-5xl.leading-3 :
            div.text-center
                span.inline-block.font-arial.text-sm.text-gray-400.mb-1 минут
                .block.font-cormorantBold.text-5xl.leading-3
                    span {{ minutesLeft < 10 ? `0${minutesLeft}` : minutesLeft }}

</template>

<script>
export default {
    name: "Timer",
    data() {
        return {
            counDownTimer: null,
            endDate: new Date('Aug 29, 2023 00:00:00'),
            daysLeft: '',
            hoursLeft: '',
            minutesLeft: '',
            secondsLeft: '',
        }
    },
    mounted() {
        this.counDownTimer = setInterval(() => {
            this.onTimer()
        }, 1000)
    },
    beforeDestroy() {
        clearInterval(() => {
            this.counDownTimer
        })
    },
    methods: {
        onTimer() {
            const remainingTime = this.endDate - new Date().getTime()
            const second = 1000
            const minute = second * 60
            const hour = minute * 60
            const day = hour * 24
            this.daysLeft = Math.trunc(remainingTime / day)
            this.hoursLeft = Math.trunc((remainingTime % day) / hour)
            this.minutesLeft = Math.trunc((remainingTime % hour) / minute)   
            this.secondsLeft = Math.trunc((remainingTime % minute) / second)
        }
    }
}
</script>

<style lang="sass">
@media screen and (max-width: 768px)
  header
    padding-top: 100px
  #sverhnovaya
    padding-top: 50px
</style>

<style lang="sass" scoped>
.mts-timer-text
    @media screen and (max-width: 768px)
        font-size: 12px
        line-height: 1.2rem
</style>