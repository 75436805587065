<style>
.container {margin: 0 auto;max-width: 1280px;padding-left: 1rem;padding-right: 1rem;}
.row {gap:8px;display: flex;flex-wrap: wrap;}
.header__logo {align-items: center;display: flex; gap:inherit;padding-bottom: 1rem;padding-top: 1rem;}
.header__logo a {height: 2.75rem;width: 6.5rem;z-index: 20;}
.logo {display:block;height: 2.75rem;width: 6.5rem;z-index: 20;background:url(./assets/logo.svg) center center no-repeat;background-size:contain;}
.header__menu {align-items: flex-start;flex-grow: 1;height: auto;justify-content: center;padding-bottom: 0.25rem;padding-top: 0.25rem;position: static;width: auto;background: rgba(255,255,255,0);display: flex;flex-direction: column;z-index: 15;transition: .4s ease;}
.header__menu--top {margin-bottom: 0;order: 1;margin-bottom: 0;width: 100%;}
.header__menu--top ul {align-items: center;display: flex;gap: 0.75rem;list-style: none;margin: 0;padding: 0;}
.header__menu--top ul li {display: flex;justify-content: center;margin-bottom:0;text-align: center;}
.header__menu--top ul li a {font-size: .75rem;align-items: center;color: #fff;display: flex;font-family:'TT Norms', sans-serif}
.header__menu--top ul li a:hover {color: #E72978;text-decoration: none;}
.header__menu--contacts {align-items: center;display: flex;gap: 12px;justify-content: center;}
.header__menu--contacts ul {align-items: center;justify-content: center;display: flex;gap: 12px;list-style: none;margin: 0;padding: 0;}
.d-none {display: none;}
.d-lg-flex {display: flex!important;}
.header__menu--contacts ul li:nth-child(2) {order: 3;}
.header__menu--contacts ul li a {background: 0 0;color: rgba(255,255,255,.5);font-size: .75rem;height: auto;width: auto;align-items: center;display: flex;justify-content: center;font-family:'TT Norms', sans-serif}
.header__menu--contacts ul li a .name {display: inline-block;}
.header__menu--contacts ul li a .icon {margin-right: 0.25rem;align-items: center;display: flex;height: 1.5em;width: 1.5em;}
.header__menu--contacts ul li a .icon.vk {background:url(./assets/icon-vk.svg) center center no-repeat;background-size:contain;}
.header__menu--contacts ul li a .icon.tg {background:url(./assets/icon-tg.svg) center center no-repeat;background-size:contain;}
.header__menu--contacts ul li a:hover {color: #E72978;text-decoration: none;}
.header__curators {align-items: center;flex-direction: column;gap: 12px;padding-bottom: 0.25rem;padding-top: 0.25rem;flex-direction: row;display: flex;position:relative;width:71px;}
.header__curators a {display:block;position:absolute;left:0;top:0;width:100%;height:100%;background:url(./assets/logo-mer.svg) center center no-repeat;background-size:contain;}
.header__personal {align-items: center;flex-grow: 0;display: flex;justify-content: flex-end;padding-bottom: 1rem;padding-top: 1rem;}
.header__personal a {align-items: center;display: flex;font-weight: 500;padding: 0.5rem 0.75rem;background: #fff;color: #1f1f1f;border-radius: 5rem;font-family:'TT Norms', sans-serif}
.header__personal a:hover {background: #E72978;color: #fff;text-decoration: none;}
@media screen and (max-width:1100px) {
.header__menu {display:none;}
.header__curators {display:none;}
.header__personal {flex-grow:1;}
}
</style>
<template lang="html">
	<div class="container">
		<div class="row">
            <div class="header__logo">
				<a href="https://mosmolodezh.ru/"><span class="logo"></span></a>
			</div>
            <nav class="header__menu">
				<div class="header__menu--top">
					<ul class="menu-top">
						<li class=""><a href="https://mosmolodezh.ru/events/"><span class="name">Мероприятия</span></a></li>
						<li class=""><a href="https://mosmolodezh.ru/co-working-zones/"><span class="name">Мультиформатное пространство</span></a></li>
						<li class=""><a href="https://mosmolodezh.ru/about/"><span class="name">О нас</span></a></li>
						<li class=""><a href="https://mosmolodezh.ru/projects/"><span class="name">Проекты</span></a></li>
						<li class=""><a href="https://mosmolodezh.ru/news/"><span class="name">Новости</span></a></li>
						<li class=" d-flex d-lg-none"><a href="/contacts/"><span class="name">Контакты</span></a></li>
					</ul>
				</div>
				<div class="header__menu--contacts">
					<ul class="menu-top_contacts">
						<li class=" d-none d-lg-flex"><a href="https://mosmolodezh.ru/contacts/"><span class="name">Контакты</span></a></li>
						<li class=""><a href="https://vk.com/molodezh_msk" target="_blank"><span class="icon vk"></span><span class="name">ВКонтакте</span></a></li>
						<li class=""><a href="https://t.me/molodezhmsk" target="_blank"><span class="icon tg"></span><span class="name">Telegram</span></a></li>
					</ul>
				</div>
			</nav>
			<div class="header__curators">
				<a href="https://www.mos.ru/" target="_blank"></a>
			</div>
			<div class="header__personal">
				<a class="btn btn_white" href="https://mosmolodezh.ru/sign_in/">Войти в кабинет</a>
			</div>
		</div>
	</div>
</template>

<script>
export default {
    name: "MosHead",
    props: {

    },
}
</script>