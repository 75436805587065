<template lang="pug">
    .flex(class="w-250 mb-10 1024:mb-0 1024:w-full flex-col")
        .flex.mb-15.items-center
            a(href="https://vk.com/mts" @click="onGTM({ eventLabel: 'logotip' })" target="_blank")
                img.block.mr-20(:src="logo.mtc")
            //a(href="https://ru.tv/" @click="onGTM({ eventLabel: 'ru_tv' })" target="_blank")
            //    img(:src="logo.rutv")
        //- .flex(class="flex-col 1024:flex-row gap-20")
        //-     a.text-xs.text-gray-500(href="https://x.mts.ru" class="hover:underline") Регистрация ИП или ООО
        //-     a.text-xs.text-gray-500(href="https://tb.mts.ru/bank" class="hover:underline") МТС банк. Открыть расчетный счет
        //-     a.text-xs.text-gray-500(href="https://tb.mts.ru/wifi" class="hover:underline") WiFi для  бизнеса
        //-     a.text-xs.text-gray-500(href="https://idscan.mts.ru/" class="hover:underline") Inside report 
</template>

<script>
export default {
    name: "FooterLogos",
    computed: {
        logo() {
            return {
                mtc: require('@/static/imgs/logos/mts_new.svg'),
                // rutv: require('@/static/imgs/logos/footer-rutv.svg'),
            }
        },
        screenName() {
            if (this.$route.name === 'Apply') return '/apply'
            if (this.$route.name === 'Details') return '/details'
            else return '/'
        },
    },
    methods: {
        onGTM({ eventLabel }) {
            const dataLayer = window.dataLayer = window.dataLayer || []; 
            dataLayer.push({
                'event': 'vntCross',
                'eventCategory' : 'kross_elementy',
                'eventAction' : 'element_click',
                'eventLabel' : eventLabel,
                'eventValue' : null,
                'screenName': this.screenName,
                'buttonLocation': 'footer',
                'actionGroup': 'interactions',   
                'touchPoint': 'web'
            });
        }
    }
}
</script>