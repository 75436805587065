<template lang="pug">
    section(class="relative flex flex-col box-border pt-60 pb-60 w-full 1440:w-1440")
        .flex.justify-center.flex-wrap.pl-20.pr-20(class="1024:pl-0 1024:pr-0")
            slot(name="title")
        .flex.justify-center
            slot(name="content")
</template>

<script>
export default {
    name: "BaseScreenWrapper",
}
</script>