<template lang="pug">
    ul
        template(v-for="(item, index) in TOP_NAVIGATION")
            li(@click="onGTM")
                router-link(
                    :to="item.to"
                    class="text-sm cursor-pointer text-accent"
                ) 
                    span.underline-pseudo {{ item.name }}
</template>

<script>
import { TOP_NAVIGATION } from '@/configs/navigation.js'
export default {
    name: "Navigation",
    computed: {
        TOP_NAVIGATION() {
            return TOP_NAVIGATION
        }
    },
    methods: {
        onGTM() {
            const dataLayer = window.dataLayer = window.dataLayer || []; 
            dataLayer.push({
                'event': 'vntSverx',
                'eventCategory' : 'sverxnovaya',
                'eventAction' : 'element_click',
                'eventLabel' : 'podat_zayavku',
                'eventValue' : null,
                'screenName': '/',
                'buttonLocation': null,
                'actionGroup': 'interactions',   
                'touchPoint': 'web'
            });
        }
    }
}
</script>

<style lang="sass" scoped>
// .router-link-active {
//     color: #2563eb;
//     position: relative;
// }
.underline-pseudo
    position: relative
    &::before        
        position: absolute
        content: ''
        width: 100%
        height: 1px
        bottom: 0px
        background-color: #FA1D27
</style>