const HEADER = {
    // description: `Мечтаешь о собственном деле?<br>
    // И у тебя уже есть бизнес-идея, но не знаешь, <br>с чего начать?
    // Прими участие в первом <br>бизнес-реалити для женщин — СВЕРХНОВАЯ <br>от МТС на телеканале RU.TV`,
    // description: `«СВЕРХНОВАЯ» - первое бизнес-реалити для женщин от МТС на телеканале RU TV.<br>
    // Шоу, в котором 20 амбициозных девушек со всей России получат уникальный шанс развить свою <br>
    // бизнес-идею вместе с профессионалами и экспертами рынка и МТС.<br>
    // Девушки будут дорабатывать свой проект вместе с коучами и готовиться к его финальной защите.<br>
    // Победительница получит 10 000 000 рублей на реализацию проекта своей мечты!<br><br>

    // Смотри бизнес-реалити «СВЕРХНОВАЯ» на телеканале RU TV с 21 октября.`,
    // description: `Шоу, в котором 20 амбициозных девушек со всей России <br>получат уникальный шанс развить свою бизнес-идею вместе с профессионалами.<br>
    // С поддержкой от лучших коучей и экспертов рынка и МТС. Участницы спланируют все необходимое для реализации идеи. Прокачают деловые навыки. И получат шанс выиграть главный приз – 10 000 000  рублей на развитие проекта своей мечты
    // <br><br>
    // Прием заявок завершен.<br>
    // Смотри бизнес-реалити на телеканале <a href="https://ru.tv">RU.TV</a> с 21 октября`,
    description: `Первое в России бизнес-реалити <br /> для девушек c главным призом  10 000 000 рублей.`,
    subtitle: `Мы ищем тебя, если ты:`,
    // blocks: [
    //     `Ты спланируешь<br> все необходимое<br> для реализации<br> идеи`,
    //     `Прокачаешь<br> деловые<br> навыки`,
    //     `Сможешь выиграть<br> главный приз<br> <strong style="color: red;">10 000 000 ₽</strong> <br>на развитие <br>своего бизнеса`
    // ],
    blocks: [
        `Спланируем <br>все необходимое <br>для реализации <br>идеи`,
        `Прокачаем<br> деловые<br> навыки`,
        `Вручим <br>главный приз <br><strong style="color: red;">10 000 000 ₽</strong> <br>на развитие <br>своего бизнеса`
    ],
    // blocks_mobile: [
    //     `Ты спланируешь<br> все необходимое<br> для реализации<br> идеи`,
    //     `Прокачаешь<br> деловые навыки`,
    //     `Сможешь выиграть<br> главный приз<br> <strong style="color: red;">10 000 000 ₽</strong> <br>на развитие <br>своего бизнеса`
    // ],
    blocks_mobile: [
        `Спланируем <br>все необходимое <br>для реализации <br>идеи`,
        `Прокачаем<br> деловые<br> навыки`,
        `Вручим <br>главный приз <br><strong style="color: red;">10 000 000 ₽</strong> <br>на развитие <br>своего бизнеса`
    ],
    mobile_description: `Первое женское <br /> бизнес реалити от МТС`,
    mobile_coach_description: `С поддержкой <br>от лучших коучей <br>и экспертов рынка <br>и МТС`,
}

const PRIZES = {
    applyTextTitle: `Заполни заявку <br>на участие <br>до 25.09.2022`,
    applyText: `Если твоя идея будет отобрана<br> 
    и попадет в шорт-лист 20 лучших<br> 
    идей, мы пригласим тебя для <br>
    записи реалити-шоу в Москве<br> 
    (10 дней в октябре 2022)`,
    applyTextDetails: 'подробнее',
    gradeBlocks: [
        {
            number: '1',
            place: 'место',
            text: 'Здесь будет приз, <br>например, бесплатная <br>ТВ приставка',
            mobile_text: 'Здесь будет приз, например, бесплатная ТВ приставка',
        },
        {
            number: '2',
            place: 'место',
            text: 'Здесь будет приз, <br>например, онлайн-<br>кинотеатр KION',
            mobile_text: 'Здесь будет приз, например, онлайн-кинотеатр KION',
        },
        {
            number: '3',
            place: 'место',
            text: 'Здесь будет приз, <br>например, высоко-<br>скоростной интернет',
            mobile_text: 'Здесь будет приз, например, высоко-скоростной интернет',
        },
    ]
}

const ABOUT = {
    bubbles: [
        'В рамках 10-ти серийного реалити мы вместе с МТС ищем тебя – девушку предпринимателя XXI века, которая может и вести собственный бизнес, и соблюдать work-life balance, еще и на сайкл ходить два раза в неделю.',
        'По результатам кастинга мы отберем 14 девушек, у которых уже есть свой бизнес или идея бизнеса, и поместим их в МТС Camp в Москве. Каждую девушку будут ждать различные испытания, направленные на развитие бизнес-навыков. Звездные жюри и эксперты будут определять, кто из девушек справился с испытанием и проходит дальше. Съемки будут проходить в сентябре.',
        'В финале останется только одна — <b class="font-cormorantBold text-lg 1024:text-2xl">СВЕРХНОВАЯ</b>.',
        'Она и получит <b class="font-cormorantBold whitespace-nowrap text-2xl 1024:text-4xl">10 000 000 рублей</b> <br /> на реализацию бизнеса своей мечты.',
    ]
}

const VEDUSHIE = {
    vedushiy_1: 'Анастасия Пронина',
    vedushiy_1_note: 'Одна строка про ведущего',
    vedushiy_2: 'Максим Кишенёв',
    vedushiy_2_note: 'Одна строка про ведущего',
}

// const EXPERTS = {}
const SERVICES = {
    subtitle: 'для стартапов и малого бизнеса'
}

const COPY = {
    footer: '2023 ПАО «МТС». Все права защищены.'
}

const FORM = {
    select_label: 'Сфера вашей бизнес идеи',
    description_label: 'Кратко опишите свою бизнес-идею:',
    lastname: 'Фамилия',
    firstname: 'Имя',
    patronymic: 'Отчество',
    phone: 'Телефон',
    city: 'Город',
    social: 'Никнейм в Телеграм',
    email: 'Email',
    date_of_birth: 'Дата рождения',
    university: 'Учебное заведение',
    competitive: 'Конкурсное направление',
    description: 'Кратко опишите свою бизнес-идею, почему вам интересна эта тема и т.д.',
    files_label: 'Видео-визитка',
    files_desc: 'Тебе необходимо записать видео-визитку. В ней нужно рассказать о себе, а также ответить на вопросы: почему ты выбрал данное конкурсное направление и какими навыками ты уже обладаешь в этой сфере.',
    grab_files: 'Перетащите или выберите файлы для загрузки',
    grab_files_mobile: 'Выберите файлы для загрузки',
    files_note: `Длительность ролика: не более 90 секунд.`,
    agreement: `Нажимая кнопку «Подать заявку» я соглашаюсь <br>на обработку <a href="https://mosmolodezh.ru/user-agreement/" style="color: #6FE5FF;">моих персональных данных*</a>`,
    experience: 'Кем и где работаете на данный момент?',
    problems: 'Что до этого мешало вам развивать свой бизнес?',
    family: 'Есть ли у вас семья/дети/личная жизнь?',
    hobby: 'Расскажите про свои хобби и увлечения',
    spend: 'На что потратите 10 миллионов рублей в случае выигрыша?',
    fact: 'Есть ли еще факты о вас, которые важны для участия в бизнес-реалити шоу?',
    links: 'Если тебе не удалось прикрепить файл в пункте выше, то загрузи его на облачное хранилище (Яндекс-Диск и т.п.) и прикрепи ссылку:',
    competitive_topics: [
        { name: 'Например, Ведущий', value: '', disabled: true, selected: true },
        {value: 'Ведущий', name: 'Ведущий'},
        {value: 'SММ', name: 'SММ'},
        {value: 'Дизайн', name: 'Дизайн'},
        {value: 'Продюсирование', name: 'Продюсирование'},
        {value: 'Редакция', name: 'Редакция'},
        {value: 'Хедлайнер', name: 'Хедлайнер'},
        {value: 'Техническое сопровождение', name: 'Техническое сопровождение'},
    ],
    topics: [
        { name: 'Например, малый бизнес', value: '', disabled: true, selected: true },
        // {value: 'hotels', name: 'Гостиницы, рестораны, общепит, кейтеринг'},
        // {value: 'gov', name: 'Государственные организации'},
        // {value: 'mining', name: 'Добывающая отрасль'},
        {value: 'zkh', name: 'ЖКХ и благоустройство'},
        {value: 'sport', name: 'Здоровье и спорт'},
        {value: 'food_industry', name: 'Индустрия питания'},
        {value: 'beauty', name: 'Красота и косметика'},
        {value: 'adv', name: 'Маркетинг и реклама'},
        {value: 'media', name: 'Медиа и развлечения'},
        {value: 'fashion', name: 'Мода и дизайн'},
        {value: 'education', name: 'Образование'},
        {value: 'fast_food', name: 'Общественное питание'},
        {value: 'tur', name: 'Путешествия и туризм'},
        {value: 'agriculture', name: 'Сельское хозяйство'},
        {value: 'social', name: 'Социальное обеспечение'},
        {value: 'construction', name: 'Строительство и архитектура'},
        {value: 'transport', name: 'Транспорт и логистика'},
        {value: 'finance', name: 'Финансы и инвестиции'},
        {value: 'eco', name: 'Экология'},
        {value: 'other', name: 'Другое'},

        // {value: 'information_tech', name: 'Информационные технологии, системная интеграция, интернет'},
        // {value: 'arts', name: 'Искусство, культура'},
        // {value: 'forest', name: 'Лесная промышленность, деревообработка'},
        // {value: 'medicine', name: 'Медицина, фармацевтика, аптеки'},
        // {value: 'metals', name: 'Металлургия, металлообработка'},
        // {value: 'oil_gas', name: 'Нефть и газ'},
        // {value: 'education', name: 'Образовательные учреждения'},
        // {value: 'social', name: 'Общественная деятельность, благотворительность'},
        // {value: 'logistics', name: 'Перевозки, логистика, склад, ВЭД'},
        // {value: 'food', name: 'Продукты питания'},
        // {value: 'technick', name: 'Промышленное оборудование, техника, станки и комплектующие'},
        // {value: 'sell', name: 'Розничная торговля'},
        // {value: 'marketing', name: 'СМИ, маркетинг, реклама, BTL, PR, дизайн, продюсирование'},
        // {value: 'real_estate', name: 'Строительство, недвижимость, эксплуатация, проектирование'},
        // {value: 'telecommunication', name: 'Телекоммуникации, связь'},
        // {value: 'non_food', name: 'Товары народного потребления (непищевые)'},
        // {value: 'heavy_machinery', name: 'Тяжелое машиностроение'},
        // {value: 'actives_managment', name: 'Управление многопрофильными активами'},
        // {value: 'btb', name: 'Услуги для бизнеса'},
        // {value: 'btc', name: 'Услуги для населения'},
        // {value: 'finance', name: 'Финансовый сектор'},
        // {value: 'chemistry', name: 'Химическое производство, удобрения'},
        // {value: 'electronics', name: 'Электроника, приборостроение, бытовая техника, компьютеры и оргтехника'},
        // {value: 'energy', name: 'Энергетика'},
    ],
    vuz_list: [
        { name: 'Например, МГТУ им. Н.Э. Баумана', value: '', disabled: false, selected: true },
        {value: 'АГПС МЧС России', name: 'АГПС МЧС России'},
        {value: 'АТиСО', name: 'АТиСО'},
        {value: 'АХИ им. В.С. Попова', name: 'АХИ им. В.С. Попова'},
        {value: 'ВАВТ', name: 'ВАВТ'},
        {value: 'ВГИК', name: 'ВГИК'},
        {value: 'ВГУЮ (РПА Минюста России)', name: 'ВГУЮ (РПА Минюста России)'},
        {value: 'ВТУ им. М. С. Щепкина', name: 'ВТУ им. М. С. Щепкина'},
        {value: 'ГАУГН', name: 'ГАУГН'},
        {value: 'ГИТИС', name: 'ГИТИС'},
        {value: 'ГИТР', name: 'ГИТР'},
        {value: 'ГМПИ имени М.М. Ипполитова-Иванова', name: 'ГМПИ имени М.М. Ипполитова-Иванова'},
        {value: 'Гос. ИРЯ им. А.С. Пушкина', name: 'Гос. ИРЯ им. А.С. Пушкина'},
        {value: 'Государственный университет «Дубна»', name: 'Государственный университет «Дубна»'},
        {value: 'ГУЗ', name: 'ГУЗ'},
        {value: 'ГУП', name: 'ГУП'},
        {value: 'ГУУ', name: 'ГУУ'},
        {value: 'ГЦОЛИФК', name: 'ГЦОЛИФК'},
        {value: 'Дипломатическая академия МИД России', name: 'Дипломатическая академия МИД России'},
        {value: 'ИМПЭ им. А.С. Грибоедова', name: 'ИМПЭ им. А.С. Грибоедова'},
        {value: 'ИМЭС', name: 'ИМЭС'},
        {value: 'ИСИ', name: 'ИСИ'},
        {value: 'МАДИ', name: 'МАДИ'},
        {value: 'МАИ', name: 'МАИ'},
        {value: 'МАРХИ', name: 'МАРХИ'},
        {value: 'МГАВМиБ имени К.И. Скрябина', name: 'МГАВМиБ имени К.И. Скрябина'},
        {value: 'МГАФК', name: 'МГАФК'},
        {value: 'МГАХИ им. В.И. Сурикова', name: 'МГАХИ им. В.И. Сурикова'},
        {value: 'МГГЭУ', name: 'МГГЭУ'},
        {value: 'МГИК', name: 'МГИК'},
        {value: 'МГИМ им. А.Г. Шнитке', name: 'МГИМ им. А.Г. Шнитке'},
        {value: 'МГИМО МИД России', name: 'МГИМО МИД России'},
        {value: 'МГК им. П.И. Чайковского', name: 'МГК им. П.И. Чайковского'},
        {value: 'МГЛУ', name: 'МГЛУ'},
        {value: 'МГМСУ им. А.И. Евдокимова', name: 'МГМСУ им. А.И. Евдокимова'},
        {value: 'МГППУ', name: 'МГППУ'},
        {value: 'МГПУ', name: 'МГПУ'},
        {value: 'МГТУ ГА', name: 'МГТУ ГА'},
        {value: 'МГТУ им. Н.Э. Баумана', name: 'МГТУ им. Н.Э. Баумана'},
        {value: 'МГТУ Станкин', name: 'МГТУ Станкин'},
        {value: 'МГУ', name: 'МГУ'},
        {value: 'МГУСиТ', name: 'МГУСиТ'},
        {value: 'МГУТУ им. К.Г. Разумовского', name: 'МГУТУ им. К.Г. Разумовского'},
        {value: 'МИИГАиК', name: 'МИИГАиК'},
        {value: 'МИП', name: 'МИП'},
        {value: 'МИТУ-МАСИ', name: 'МИТУ-МАСИ'},
        {value: 'МИЭТ', name: 'МИЭТ'},
        {value: 'ММУ', name: 'ММУ'},
        {value: 'МОСАП', name: 'МОСАП'},
        {value: 'МосГУ', name: 'МосГУ'},
        {value: 'Московский Политех', name: 'Московский Политех'},
        {value: 'МПГУ', name: 'МПГУ'},
        {value: 'МСПИ', name: 'МСПИ'},
        {value: 'МСЭИ', name: 'МСЭИ'},
        {value: 'МТИ', name: 'МТИ'},
        {value: 'МТУСИ', name: 'МТУСИ'},
        {value: 'МУ им. С.Ю. Витте', name: 'МУ им. С.Ю. Витте'},
        {value: 'МФТИ', name: 'МФТИ'},
        {value: 'МФЮА', name: 'МФЮА'},
        {value: 'МХПИ', name: 'МХПИ'},
        {value: 'МЭИ', name: 'МЭИ'},
        {value: 'МЮИ', name: 'МЮИ'},
        {value: 'НИТУ МИСИС', name: 'НИТУ МИСИС'},
        {value: 'НИУ ВШЭ', name: 'НИУ ВШЭ'},
        {value: 'НИУ МГСУ', name: 'НИУ МГСУ'},
        {value: 'НИУ МЭИ', name: 'НИУ МЭИ'},
        {value: 'НИЯУ МИФИ', name: 'НИЯУ МИФИ'},
        {value: 'Первый МГМУ им. И.М. Сеченова', name: 'Первый МГМУ им. И.М. Сеченова'},
        {value: 'ПСТГУ', name: 'ПСТГУ'},
        {value: 'РААН', name: 'РААН'},
        {value: 'РАМ имени Гнесиных', name: 'РАМ имени Гнесиных'},
        {value: 'РАНХиГС', name: 'РАНХиГС'},
        {value: 'РГАУ-МСХА имени К.А. Тимирязева', name: 'РГАУ-МСХА имени К.А. Тимирязева'},
        {value: 'РГГРУ имени Серго Орджоникидзе, МГРИ', name: 'РГГРУ имени Серго Орджоникидзе, МГРИ'},
        {value: 'РГГУ', name: 'РГГУ'},
        {value: 'РГСАИ', name: 'РГСАИ'},
        {value: 'РГСУ', name: 'РГСУ'},
        {value: 'РГУ им. А.Н. Косыгина', name: 'РГУ им. А.Н. Косыгина'},
        {value: 'РГУ нефти и газа имени И.М. Губкина', name: 'РГУ нефти и газа имени И.М. Губкина'},
        {value: 'РГУП', name: 'РГУП'},
        {value: 'РГУТИС', name: 'РГУТИС'},
        {value: 'РГХПУ им. С. Г. Строганова', name: 'РГХПУ им. С. Г. Строганова'},
        {value: 'Реавиз', name: 'Реавиз'},
        {value: 'РНИМУ им. Н.И. Пирогова', name: 'РНИМУ им. Н.И. Пирогова'},
        {value: 'РОСБИОТЕХ', name: 'РОСБИОТЕХ'},
        {value: 'РосНОУ', name: 'РосНОУ'},
        {value: 'Российская академия живописи, ваяния и зодчества Ильи Глазунова', name: 'Российская академия живописи, ваяния и зодчества Ильи Глазунова'},
        {value: 'РТА', name: 'РТА'},
        {value: 'РТУ МИРЭА', name: 'РТУ МИРЭА'},
        {value: 'РУДН', name: 'РУДН'},
        {value: 'РУТ (МИИТ)', name: 'РУТ (МИИТ)'},
        {value: 'РХТУ им. Д.И. Менделеева', name: 'РХТУ им. Д.И. Менделеева'},
        {value: 'РЭУ им. Г.В. Плеханова', name: 'РЭУ им. Г.В. Плеханова'},
        {value: 'Синергия', name: 'Синергия'},
        {value: 'УМЦ им. В.В. Жириновского', name: 'УМЦ им. В.В. Жириновского'},
        {value: 'Университет имени О.Е. Кутафина (МГЮА)', name: 'Университет имени О.Е. Кутафина (МГЮА)'},
        {value: 'Университет Правительства Москвы', name: 'Университет Правительства Москвы'},
        {value: 'Университет прокуратуры', name: 'Университет прокуратуры'},
        {value: 'Финансовый университет', name: 'Финансовый университет'},
    ],
    ssyz_list: [
        { name: 'Например, Московский городской открытый колледж', value: '', disabled: false, selected: true },
        {value: 'Колледж Российского нового университета', name: 'Колледж Российского нового университета'},
        {value: 'Колледж информатики и программирования Финансового университета при Правительстве Российской Федерации', name: 'Колледж информатики и программирования Финансового университета при Правительстве Российской Федерации'},
        {value: 'Колледж телекоммуникаций Московского технического университета связи и информатики', name: 'Колледж телекоммуникаций Московского технического университета связи и информатики'},
        {value: 'Московский издательско-полиграфический колледж имени Ивана Федорова', name: 'Московский издательско-полиграфический колледж имени Ивана Федорова'},
        {value: 'Колледж мировой экономики и передовых технологий Института международных экономических связей', name: 'Колледж мировой экономики и передовых технологий Института международных экономических связей'},
        {value: 'Колледж Московского финансово-промышленного университета "Синергия"', name: 'Колледж Московского финансово-промышленного университета "Синергия"'},
        {value: 'Колледж многоуровневого профессионального образования Российской академии народного хозяйства и государственной службы при Президенте Российской Федерации', name: 'Колледж многоуровневого профессионального образования Российской академии народного хозяйства и государственной службы при Президенте Российской Федерации'},
        {value: 'Московский городской открытый колледж', name: 'Московский городской открытый колледж'},
        {value: 'Колледж Московского транспорта', name: 'Колледж Московского транспорта'},
        {value: 'Московский финансовый колледж Финансового университета при Правительстве Российской Федерации', name: 'Московский финансовый колледж Финансового университета при Правительстве Российской Федерации'},
        {value: 'Колледж Московского технологического института', name: 'Колледж Московского технологического института'},
        {value: 'Колледж геодезии и картографии Московского государственного университета геодезии и картографии', name: 'Колледж геодезии и картографии Московского государственного университета геодезии и картографии'},
        {value: 'Юридический колледж Российского государственного университета правосудия', name: 'Юридический колледж Российского государственного университета правосудия'},
        {value: 'Колледж Московского гуманитарного университета', name: 'Колледж Московского гуманитарного университета'},
        {value: 'Колледж кино, телевидения и мультимедиа Всероссийского государственного института кинематографии имени С.А. Герасимова', name: 'Колледж кино, телевидения и мультимедиа Всероссийского государственного института кинематографии имени С.А. Герасимова'},
        {value: 'Политехнический колледж № 8 имени дважды Героя Советского Союза И.Ф. Павлова', name: 'Политехнический колледж № 8 имени дважды Героя Советского Союза И.Ф. Павлова'},
        {value: 'Колледж физической культуры и спорта Спарта Департамента спорта города Москвы', name: 'Колледж физической культуры и спорта Спарта Департамента спорта города Москвы'},
        {value: 'Образовательный комплекс "Воробьевы горы"', name: 'Образовательный комплекс "Воробьевы горы"'},
        {value: 'Московский автомобильно-дорожный колледж им. А.А. Николаева', name: 'Московский автомобильно-дорожный колледж им. А.А. Николаева'},
        {value: 'Московский техникум креативных индустрий им. Л.Б. Красина', name: 'Московский техникум креативных индустрий им. Л.Б. Красина'},
        {value: 'Технический пожарно-спасательный колледж имени Героя Российской Федерации В.М. Максимчука', name: 'Технический пожарно-спасательный колледж имени Героя Российской Федерации В.М. Максимчука'},
        {value: 'Колледж сферы услуг № 10', name: 'Колледж сферы услуг № 10'},
        {value: 'Московский колледж бизнес-технологий', name: 'Московский колледж бизнес-технологий'},
        {value: 'Медицинский колледж № 7', name: 'Медицинский колледж № 7'},
        {value: 'Колледж Государственного музыкально-педагогического института имени М.М. Ипполитова-Иванова', name: 'Колледж Государственного музыкально-педагогического института имени М.М. Ипполитова-Иванова'},
        {value: 'Колледж Академии водного транспорта', name: 'Колледж Академии водного транспорта'},
        {value: 'Факультет среднего медицинского образования Московского государственного медико-стоматологического университета имени А.И. Евдокимова', name: 'Факультет среднего медицинского образования Московского государственного медико-стоматологического университета имени А.И. Евдокимова'},
        {value: 'Колледж дизайна Московского художественно-промышленного института', name: 'Колледж дизайна Московского художественно-промышленного института'},
        {value: 'Колледж гостиничного сервиса Московского филиала Российской международной академии туризма', name: 'Колледж гостиничного сервиса Московского филиала Российской международной академии туризма'},
        {value: 'Колледж Российской академии адвокатуры и нотариата', name: 'Колледж Российской академии адвокатуры и нотариата'},
        {value: 'Колледж администрирования и современных технологий', name: 'Колледж администрирования и современных технологий'},
        {value: 'Колледж информатики и дизайна', name: 'Колледж информатики и дизайна'},
        {value: 'Московский международный колледж цифровых технологий Академия TOP', name: 'Московский международный колледж цифровых технологий Академия TOP'},
        {value: 'Школа классического танца', name: 'Школа классического танца'},
        {value: 'Колледж программирования и кибербезопасности РТУ МИРЭА', name: 'Колледж программирования и кибербезопасности РТУ МИРЭА'},
        {value: 'Колледж Министерства иностранных дел Российской Федерации', name: 'Колледж Министерства иностранных дел Российской Федерации'},
        {value: 'Факультет среднего профессионального образования Академии труда и социальных отношений', name: 'Факультет среднего профессионального образования Академии труда и социальных отношений'},
        {value: 'Колледж Всероссийского государственного университета юстиции (РПА Минюста России)', name: 'Колледж Всероссийского государственного университета юстиции (РПА Минюста России)'},
        {value: 'Московское среднее специальное училище олимпийского резерва № 2 (колледж) Департамента спорта города Москвы', name: 'Московское среднее специальное училище олимпийского резерва № 2 (колледж) Департамента спорта города Москвы'},
        {value: 'Колледж связи №54 им. П.М. Вострухина', name: 'Колледж связи №54 им. П.М. Вострухина'},
        {value: 'Академия джаза', name: 'Академия джаза'},
        {value: 'Колледж музыкально-театрального искусства имени Г.П. Вишневской', name: 'Колледж музыкально-театрального искусства имени Г.П. Вишневской'},
        {value: 'Московский государственный колледж электромеханики и информационных технологий', name: 'Московский государственный колледж электромеханики и информационных технологий'},
        {value: 'Московский музыкально-педагогический колледж', name: 'Московский музыкально-педагогический колледж'},
        {value: 'Московский образовательный комплекс "Запад"', name: 'Московский образовательный комплекс "Запад"'},
        {value: 'Политехнический колледж им. Н.Н. Годовикова', name: 'Политехнический колледж им. Н.Н. Годовикова'},
        {value: 'Технологический колледж № 34', name: 'Технологический колледж № 34'},
        {value: 'Колледж сферы услуг № 32', name: 'Колледж сферы услуг № 32'},
        {value: 'Медицинский колледж № 1', name: 'Медицинский колледж № 1'},
        {value: 'Факультет среднего профессионального образования Московской государственной академии хореографии', name: 'Факультет среднего профессионального образования Московской государственной академии хореографии'},
        {value: 'Международный колледж бизнеса и дизайна', name: 'Международный колледж бизнеса и дизайна'},
        {value: 'Налоговый колледж', name: 'Налоговый колледж'},
        {value: 'Отделение среднего профессионального образования Института традиционного прикладного искусства - Московского филиала Высшей школы народных искусств (академии)', name: 'Отделение среднего профессионального образования Института традиционного прикладного искусства - Московского филиала Высшей школы народных искусств (академии)'},
        {value: 'Институт среднего профессионального образования им. К.Д. Ушинского Московского городского педагогического университета', name: 'Институт среднего профессионального образования им. К.Д. Ушинского Московского городского педагогического университета'},
        {value: 'Кинологический колледж Московской государственной академии ветеринарной медицины и биотехнологии им. К.И. Скрябина', name: 'Кинологический колледж Московской государственной академии ветеринарной медицины и биотехнологии им. К.И. Скрябина'},
        {value: 'Отделение среднего профессионального образования Российского университета спорта ГЦОЛИФК', name: 'Отделение среднего профессионального образования Российского университета спорта ГЦОЛИФК'},
        {value: 'Колледж экономики, права и информационных технологий', name: 'Колледж экономики, права и информационных технологий'},
        {value: 'Медицинский колледж № 2', name: 'Медицинский колледж № 2'},
        {value: 'Колледж сценических искусств и дизайна', name: 'Колледж сценических искусств и дизайна'},
        {value: 'Колледж Архитектуры, Дизайна и Реинжиниринга № 26', name: 'Колледж Архитектуры, Дизайна и Реинжиниринга № 26'},
        {value: 'Московский промышленно-экономический колледж Российского экономического университета имени Г.В. Плеханова', name: 'Московский промышленно-экономический колледж Российского экономического университета имени Г.В. Плеханова'},
        {value: 'Западный комплекс непрерывного образования', name: 'Западный комплекс непрерывного образования'},
        {value: 'Колледж полиции', name: 'Колледж полиции'},
        {value: 'Московский государственный образовательный комплекс', name: 'Московский государственный образовательный комплекс'},
        {value: 'Московский технологический колледж', name: 'Московский технологический колледж'},
        {value: 'Московский педагогический колледж', name: 'Московский педагогический колледж'},
        {value: 'Политехнический колледж имени П.А. Овчинникова', name: 'Политехнический колледж имени П.А. Овчинникова'},
        {value: 'Финансовый колледж № 35', name: 'Финансовый колледж № 35'},
        {value: 'Колледж по подготовке социальных работников имени Е.И. Холостовой Департамента труда и социальной защиты населения города Москвы', name: 'Колледж по подготовке социальных работников имени Е.И. Холостовой Департамента труда и социальной защиты населения города Москвы'},
        {value: 'Московский областной медицинский колледж № 1', name: 'Московский областной медицинский колледж № 1'},
        {value: 'Колледж дизайна и декоративного искусства Российского государственного художественно-промышленного университета им. С.Г. Строганова', name: 'Колледж дизайна и декоративного искусства Российского государственного художественно-промышленного университета им. С.Г. Строганова'},
        {value: 'Колледж Московского педагогического государственного университета', name: 'Колледж Московского педагогического государственного университета'},
        {value: 'Колледж Инфолайн', name: 'Колледж Инфолайн'},
        {value: 'Интерколледж', name: 'Интерколледж'},
        {value: 'Фармацевтический колледж "Новые знания"', name: 'Фармацевтический колледж "Новые знания"'},
        {value: 'Московский колледж транспорта Российского университета транспорта (МИИТ)', name: 'Московский колледж транспорта Российского университета транспорта (МИИТ)'},
        {value: 'Гуманитарно-правовой колледж института экономики, управления и права Московского городского педагогического университета', name: 'Гуманитарно-правовой колледж института экономики, управления и права Московского городского педагогического университета'},
        {value: 'Медицинский колледж Российского университета транспорта (МИИТ)', name: 'Медицинский колледж Российского университета транспорта (МИИТ)'},
        {value: 'Медицинский колледж № 6', name: 'Медицинский колледж № 6'},
        {value: 'Останкинский колледж современного управления, кино и телевидения', name: 'Останкинский колледж современного управления, кино и телевидения'},
        {value: 'Университетский колледж', name: 'Университетский колледж'},
        {value: 'Колледж экономических международных связей', name: 'Колледж экономических международных связей'},
        {value: 'Айти колледж Хекслет (Москва)', name: 'Айти колледж Хекслет (Москва)'},
        {value: 'Столичный Бизнес Колледж', name: 'Столичный Бизнес Колледж'},
        {value: 'Колледж Московского информационно-технологического университета - Московского архитектурно-строительного института', name: 'Колледж Московского информационно-технологического университета - Московского архитектурно-строительного института'},
        {value: 'Колледж предпринимательства № 11', name: 'Колледж предпринимательства № 11'},
        {value: 'Колледж архитектуры и строительства № 7', name: 'Колледж архитектуры и строительства № 7'},
        {value: 'Колледж современных технологий имени Героя Советского Союза М.Ф. Панова', name: 'Колледж современных технологий имени Героя Советского Союза М.Ф. Панова'},
        {value: 'Московский государственный театральный колледж (техникум) имени Л.А. Филатова', name: 'Московский государственный театральный колледж (техникум) имени Л.А. Филатова'},
        {value: 'Педагогический колледж № 18 Митино', name: 'Педагогический колледж № 18 Митино'},
        {value: 'Экономико-технологический колледж № 22', name: 'Экономико-технологический колледж № 22'},
        {value: 'Юридический колледж', name: 'Юридический колледж'},
        {value: 'Технологический колледж № 21', name: 'Технологический колледж № 21'},
        {value: 'Столичный профессиональный колледж', name: 'Столичный профессиональный колледж'},
        {value: 'Факультет среднего профессионального образования "Колледж" Московского гуманитарно-экономического университета', name: 'Факультет среднего профессионального образования "Колледж" Московского гуманитарно-экономического университета'},
        {value: 'Колледж Российского государственного университета туризма и сервиса', name: 'Колледж Российского государственного университета туризма и сервиса'},
        {value: 'Московский технологический колледж питания Российского экономического университета имени Г.В. Плеханова', name: 'Московский технологический колледж питания Российского экономического университета имени Г.В. Плеханова'},
        {value: 'Гуманитарный колледж Российского государственного гуманитарного университета', name: 'Гуманитарный колледж Российского государственного гуманитарного университета'},
        {value: 'Правовой колледж Юридического института Российского университета транспорта (МИИТ)', name: 'Правовой колледж Юридического института Российского университета транспорта (МИИТ)'},
        {value: 'Международный институт бизнеса и управления (колледж)', name: 'Международный институт бизнеса и управления (колледж)'},
        {value: 'Колледж права Высшей школы права Московского государственного юридического университета имени О.Е. Кутафина (МГЮА)', name: 'Колледж права Высшей школы права Московского государственного юридического университета имени О.Е. Кутафина (МГЮА)'},
        {value: 'IT-колледж Российского нового университета', name: 'IT-колледж Российского нового университета'},
        {value: 'Московский колледж деловой карьеры', name: 'Московский колледж деловой карьеры'},
        {value: 'Московская средняя специальная музыкальная школа (колледж) имени Гнесиных', name: 'Московская средняя специальная музыкальная школа (колледж) имени Гнесиных'},
        {value: 'Колледж Российского государственного социального университета', name: 'Колледж Российского государственного социального университета'},
        {value: 'Московский образовательный комплекс имени Виктора Талалихина', name: 'Московский образовательный комплекс имени Виктора Талалихина'},
        {value: 'Московский государственный колледж музыкального исполнительства имени Ф. Шопена', name: 'Московский государственный колледж музыкального исполнительства имени Ф. Шопена'},
        {value: 'Московский индустриальный колледж', name: 'Московский индустриальный колледж'},
        {value: 'Колледж сферы услуг №3', name: 'Колледж сферы услуг №3'},
        {value: 'Колледж Первого Московского Образовательного Комплекса', name: 'Колледж Первого Московского Образовательного Комплекса'},
        {value: 'Театральный художественно-технический колледж', name: 'Театральный художественно-технический колледж'},
        {value: 'Колледж индустрии гостеприимства и менеджмента № 23', name: 'Колледж индустрии гостеприимства и менеджмента № 23'},
        {value: 'Киноколледж № 40 "Московская международная киношкола"', name: 'Киноколледж № 40 "Московская международная киношкола"'},
        {value: 'Юридический колледж Международного юридического института', name: 'Юридический колледж Международного юридического института'},
        {value: 'Институт среднего профессионального образования Московского государственного университета спорта и туризма', name: 'Институт среднего профессионального образования Московского государственного университета спорта и туризма'},
        {value: 'Колледж Российского государственного университета им. А.Н. Косыгина (Технологии. Дизайн. Искусство)', name: 'Колледж Российского государственного университета им. А.Н. Косыгина (Технологии. Дизайн. Искусство)'},
        {value: 'Колледж инновационных технологий и сервиса "Галактика"', name: 'Колледж инновационных технологий и сервиса "Галактика"'},
        {value: '"Медицинский колледж" Управления делами Президента Российской Федерации', name: '"Медицинский колледж" Управления делами Президента Российской Федерации'},
        {value: 'Образовательный комплекс градостроительства "Столица"', name: 'Образовательный комплекс градостроительства "Столица"'},
        {value: 'Университетский колледж информационных технологий Московского государственного университета технологий и управления им. К.Г. Разумовского', name: 'Университетский колледж информационных технологий Московского государственного университета технологий и управления им. К.Г. Разумовского'},
        {value: 'Международный колледж информационных технологий IThub college', name: 'Международный колледж информационных технологий IThub college'},
        {value: 'Колледж Национального института дизайна', name: 'Колледж Национального института дизайна'},
        {value: 'Колледж культуры и спорта', name: 'Колледж культуры и спорта'},
        {value: 'Колледж современного управления', name: 'Колледж современного управления'},
        {value: 'Колледж Российского государственного геологоразведочного университета имени Серго Орджоникидзе (МГРИ)', name: 'Колледж Российского государственного геологоразведочного университета имени Серго Орджоникидзе (МГРИ)'},
        {value: 'Московский банковский экономико-правовой колледж', name: 'Московский банковский экономико-правовой колледж'},
        {value: 'Колледж (факультет среднего профессионального образования) Московского финансово-юридического университета', name: 'Колледж (факультет среднего профессионального образования) Московского финансово-юридического университета'},
        {value: 'Технологический колледж № 24', name: 'Технологический колледж № 24'},
        {value: 'Московский колледж архитектуры и градостроительства', name: 'Московский колледж архитектуры и градостроительства'},
        {value: 'Образовательный комплекс "Юго-Запад"', name: 'Образовательный комплекс "Юго-Запад"'},
        {value: 'Пищевой колледж № 33', name: 'Пищевой колледж № 33'},
        {value: 'Колледж малого бизнеса № 4', name: 'Колледж малого бизнеса № 4'},
        {value: 'Колледж автоматизации и информационных технологий № 20', name: 'Колледж автоматизации и информационных технологий № 20'},
        {value: 'Медицинский колледж № 5', name: 'Медицинский колледж № 5'},
        {value: 'Московский колледж управления, гостиничного бизнеса и информационных технологий "Царицыно"', name: 'Московский колледж управления, гостиничного бизнеса и информационных технологий "Царицыно"'},
        {value: 'Музыкальный колледж Московского государственного института музыки имени А.Г. Шнитке', name: 'Музыкальный колледж Московского государственного института музыки имени А.Г. Шнитке'},
        {value: 'Отделение среднего профессионального образования Института непрерывного образования Московского государственного юридического университета имени О.Е. Кутафина', name: 'Отделение среднего профессионального образования Института непрерывного образования Московского государственного юридического университета имени О.Е. Кутафина'},
        {value: 'Колледж Московского университета имени С.Ю. Витте', name: 'Колледж Московского университета имени С.Ю. Витте'},
        {value: 'Медицинский колледж Первого Московского государственного медицинского университета имени И.М. Сеченова', name: 'Медицинский колледж Первого Московского государственного медицинского университета имени И.М. Сеченова'},
        {value: 'Колледж современных технологий и медицины', name: 'Колледж современных технологий и медицины'},
        {value: 'Международный колледж искусств и коммуникаций Института гуманитарного образования и информационных технологий', name: 'Международный колледж искусств и коммуникаций Института гуманитарного образования и информационных технологий'},
        {value: 'Колледж экономики, страхового дела и информационных технологий КЭСИ', name: 'Колледж экономики, страхового дела и информационных технологий КЭСИ'},
        {value: 'Московский международный колледж', name: 'Московский международный колледж'},
        {value: 'Международный технологический колледж Российского биотехнологического университета', name: 'Международный технологический колледж Российского биотехнологического университета'},
        {value: 'Художественно-гуманитарный колледж', name: 'Художественно-гуманитарный колледж'},
        {value: 'Московский колледж информационных технологий', name: 'Московский колледж информационных технологий'},
    ],
}

const PARTICIPANTS = [
    { firstname: 'Юлия', lastname: 'Иванова', description: 'Мечтаю открыть аниме-студию в России с японскими художниками.', isActive: true },
    { firstname: 'Анна', lastname: 'Комарова', description: 'Мечтаю открыть аниме-студию в России с японскими художниками.', isActive: true },
    { firstname: 'Алиса', lastname: 'Ермолаева', description: 'Мечтаю открыть аниме-студию в России с японскими художниками.', isActive: true },
    { firstname: 'Александра', lastname: 'Титова', description: 'Мечтаю открыть аниме-студию в России с японскими художниками.', isActive: true },
    { firstname: 'Камила', lastname: 'Широкова', description: 'Мечтаю открыть аниме-студию в России с японскими художниками.', isActive: true },
    { firstname: 'Юлия', lastname: 'Иванова', description: 'Мечтаю открыть аниме-студию в России с японскими художниками.', isActive: true },
]

export {
    ABOUT,
    HEADER,
    PRIZES,
    VEDUSHIE,
    // EXPERTS,
    SERVICES,
    COPY,
    FORM,
    PARTICIPANTS,
}
