<template lang="pug">
    img.cursor-pointer.z-200(:src="logo" class="w-full" @click="onLogo")
</template>

<script>
export default {
    name: "MTSLogo",
    computed: {
        logo() {
            return require('../../../static/imgs/logos/mts_new.svg')
        },
        screenName() {
            if (this.$route.name === 'Apply') return '/apply'
            if (this.$route.name === 'Details') return '/details'
            else return '/'
        }
    },
    methods: {
        onLogo() {
            this.onGTM()
            window.open('https://mts.ru/')
        },
        onGTM() {
            const dataLayer = window.dataLayer = window.dataLayer || []; 
            dataLayer.push({
                'event': 'vntCross',
                'eventCategory' : 'kross_elementy',
                'eventAction' : 'element_click',
                'eventLabel' : 'logotip',
                'eventValue' : null,
                'screenName': this.screenName,
                'buttonLocation': 'header',
                'actionGroup': 'interactions',   
                'touchPoint': 'web'
            });
        }
    }
}
</script>